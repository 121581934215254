import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./Guide.css";
function GuidePage() {
  return (
    <div className="">
      <Header />
      <section className="container-fluid Guidepage py-5">
        <div className="container">
          <div className="row g-3 p-sm-3 p-3 px-sm-5 rounded-4" style={{background: "#23935c40"}}>
            <div className="col-12">
              <div className="headomg  fw-bold h2 mb-3" style={{color: "#00ff82", letterSpacing: "2px"}}>
                NEXAGLOBAL Beginner's Guide
              </div>
              <p className="text-white fs-4">
                Welcome to NEXAGLOBAL Dashboard! start your journey with
                quick and easy steps:
              </p>
            </div>
            <div className="col-12">
              <h4 className="headomg h2 mb-3" style={{color: "#00ff82", letterSpacing: "2px"}}>
                SIGN UP PAGE
              </h4>
              <h5 className="text-white fs-4 mb-3">
                This guide will walk you through the steps to SignUp. Follow
                these steps carefully, and refer to the images provided at each
                step for visual guidance.
              </h5>
              <ol>
                <li className="fs-4 mb-2" style={{color: "#fff", letterSpacing: "2px"}}>
                  Step 1: connect to wallet
                </li>
                <li className="fs-4 mb-2" style={{color: "#fff", letterSpacing: "2px"}}>
                  Step 2: Click to Register
                </li>
                <li className="fs-4 mb-2" style={{color: "#fff", letterSpacing: "2px"}}>
                  Step 3: Enter Upline Id
                </li>
                <li className="fs-4 mb-2" style={{color: "#fff", letterSpacing: "2px"}}>
                  Step 4: Select to Position
                </li>
                <li className="fs-4 mb-2" style={{color: "#fff", letterSpacing: "2px"}}>
                  Step 5: Confirm To Register
                </li>
              </ol>
            </div>
            <div className="col-12">
              <h2 className="fw-bold mb-3" style={{color: "#00ff82", letterSpacing: "2px"}}>
                1. Connected to wallet
              </h2>
              <div className="smallimage" style={{height: "350px"}}>
                <img className="h-100 rounded-3" src="/Guide/image3.jpg" alt="" />
              </div>
            </div>
            <div className="col-12">
              <h2 className="fw-bold mb-3" style={{color: "#00ff82", letterSpacing: "2px"}}>
              2. Click to Register
              </h2>
              <div className="smallimage" style={{height: "350px"}}>
                <img className="h-100 rounded-3" src="/Guide/image3.jpg" alt="" />
              </div>
            </div>
            <div className="col-12">
              <h2 className="fw-bold mb-3" style={{color: "#00ff82", letterSpacing: "2px"}}>
              3. Enter Upline ID
              </h2>
              <div className="smallimage"style={{height: "350px"}}>
                <img className="h-100 rounded-3" src="/Guide/image2.jpg" alt="" />
              </div>
            </div>
            <div className="col-12">
              <h2 className="fw-bold mb-3" style={{color: "#00ff82", letterSpacing: "2px"}}>
              4. Select to Position
              </h2>
              <div className="smallimage"style={{height: "350px"}}>
                <img className="h-100 rounded-3" src="/Guide/image2.jpg" alt="" />
              </div>
            </div>
            <div className="col-12">
              <h2 className="fw-bold mb-3" style={{color: "#00ff82", letterSpacing: "2px"}}>
              4. Confirm Register
              </h2>
              <div className="smallimage"style={{height: "350px"}}>
                <img className="h-100 rounded-3" src="/Guide/image3.jpg" alt="" />
              </div>
            </div>
            <div className="col-12">
              <h2 className="fw-bold mb-3" style={{color: "#00ff82", letterSpacing: "2px"}}>
              4. Register Than Login
              </h2>
              <div className="smallimage"style={{height: "350px"}}>
                <img className="h-100 rounded-3" src="/Guide/image1.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default GuidePage;
