import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { API } from "../../Utils/API";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import DOMPurify from "dompurify";
import { loadWeb3 } from "../../Utils/contract";
import { updateAuth } from "../../Redux/AuthSlice";
import "./Register.css";

const Register = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sponser, setSponser] = useState(searchParams.get("userid") ?? 224466);
  const [selectPosition, setselectPosition] = useState(
    searchParams.get("position") === "Left"
      ? 1
      : searchParams.get("position") === "Right"
        ? 2
        : ""
  );

  let navigate = useNavigate();
  const [registor_Model, setregistor_Model] = useState(false);
  const [connected, setconnected] = useState(true);
  const [sid, setSid] = useState(sponser);
  const [walletAddress, setWalletAddress] = useState("");
  const [LoginId, setLoginId] = useState();

  // console.log("sid", sid);
  const dispatch = useDispatch();

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();
      let response = await axios.get("https://api64.ipify.org/?format=json");
      console.log("response Geolocation", response.data.ip);
      // setIP(response.data.IPv4);
      let ipaddress = response.data.ip;

      if (acc === "No Wallet" || acc === undefined) {
        toast.error("No Wallet");
        setconnected("Wallet is locked");
      } else if (acc === "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSc Network");
        console.log("Wrong Network Please Connect BSC Scan Network");
        setconnected("Wrong Network");
      } else {
        // setaddress(acc)
        setconnected("Wallet is Connected");
        // setLoginId("0X484Demo848sdcssd48");
        setLoginId(acc);
        let res = await API.post(`/userlogin`, {
          password: acc,
          ipaddress: ipaddress,
        });
        let UserID = res.data.data;
        if (res.data.data.result === "Successfull") {
          toast.success(`Login Successful`);
          dispatch(
            updateAuth({
              isAuth: true,
              userId: res.data.data.uid_output,
              jwtToken: res.data.token,
              ipAddress: ipaddress,
            })
          );
          navigate("/DashBoard");
        } else {
          toast.error(res.data.data.result);
        }
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const registerUser = async () => {
    let acc = await loadWeb3();
    let response = await axios.get("https://api64.ipify.org/?format=json");
    console.log("response Geolocation", response.data.ip);
    // setIP(response.data.IPv4);

    let ipaddress = response.data.ip;
    if (!acc) {
      toast.error("Wrong Network Please Connect BSc Network");
      setconnected("Wallet is locked");
    }
    else if (acc === "No Wallet" || acc === undefined) {
      toast.error("No Wallet");
      setconnected("Wallet is locked");
    }
    else if (acc == "Wrong Network") {
      toast.error("Wrong Network Please Connect BSc Network");
      setconnected("Wrong Network");
    } else {
      try {
        if (selectPosition === "" || selectPosition === undefined) {
          toast.error("Select Position!!");
        }
        if (sid === "" || sid === undefined) {
          toast.error("Enter Upline Id");
        }
        let res = await API.post("registration", {
          sid: sid,
          walletAddress: acc,
          position: selectPosition,
        });
        // console.log("registration", res.data);
        if (res.data.data.result === "Successfull") {
          toast.success("Register Successfull");
          let res = await API.post(`/userlogin`, {
            password: acc,
            ipaddress: ipaddress,
          });

          let UserID = res.data.data;
          if (res.data.data.result === "Successfull") {
            toast.success(`Login Successful`);
            dispatch(
              updateAuth({
                isAuth: true,
                userId: res.data.data.uid_output,
                jwtToken: res.data.token,
                ipAddress: ipaddress,
              })
            );
            navigate("/DashBoard");
          } else {
            toast.error(`Invalid User id or Address`);
          }
        } else {
          toast.error(`${res.data.data.result}`);
        }
      } catch (e) {
        console.log("Error while regiater", e);
      }
    }
  };

  useEffect(() => {
    walletConnected();
  }, []);

  return (
    <div>
      <div className="container-fluid loginpage vh-100">
        <div className="container h-100">
          <div className="row h-100 py-5 py-lg-0">
            <div className="col-lg-5 col-md-7 col-sm-12 col-12 h-100 mx-auto d-flex align-items-center justify-content-md-center flex-column">
              <div className="card border-theme1 w-100">
                <div className="card-body">
                  <div className="row mx-0 align-items-center justify-content-center gy-3">
                    <div className="col-12">
                      <div className="logoimage mx-auto" style={{width: "200px"}}>
                        <img
                          className="w-100 h-100"
                          src="./assets/image/logo/logo.png"
                          alt="logo"
                        />
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <div className="loginheading text-center">
                        Access Your Personal Account
                      </div>
                      <div className="subtext text-center">Wallet is Locked</div>
                    </div>
                    <div className="col-12 text-center">
                      <button
                        className="btn btn-theme1 btnlogin"
                        onClick={() => setregistor_Model(true)}
                      >
                        Register
                      </button>
                    </div>
                    <div className="col-12">
                      <div className="remembertext text-center">
                        Remember to authorize with the correct address.View an
                        Account
                      </div>
                    </div>
                    <div className="col-12">
                      <input style={{ height: "47px" }}
                        type="text"
                        placeholder="Please Enter ID or Address"
                        className="control-form w-100 border-0 rounded-3 ps-2 fs-6"
                        value={LoginId}
                        readOnly
                      />
                    </div>
                    <div className="col-12 text-center">
                      <button className="btn btn-theme1 btnlogin">
                        Connect to Wallet
                      </button>
                    </div>
                    <div className="col-12">
                      <div className="remembertext text-center">
                        Enter the account id or  Address
                      </div>
                    </div>
                    <div
                      id="myModal"
                      className="modal"
                      style={{ display: registor_Model ? "block" : "none" }}
                    >
                      <div className="modal-content-register boxset">
                        <h4>Enter Upline Id</h4>
                        <br />
                        <p>
                          {" "}
                          <input
                            id="txtsid"
                            // className="input-id"
                            className="control-form"
                            value={sid}
                            maxLength={8}
                            type="text"
                            min={0}
                            placeholder="Enter Upline Id"
                            onChange={(e) => {
                              const inputText = e.target.value
                                .replace(/[^0-9]/gi, "")
                                .substring(0, 8);
                              const sanitizedInput =
                                DOMPurify.sanitize(inputText);
                              setSid(sanitizedInput);
                            }}
                          />
                        </p>
                        <select
                          className="form-control"
                          id="__BVID__17"
                          value={selectPosition}
                          onChange={(e) => setselectPosition(e.target.value)}
                        >
                          <option selected>Please Select Position</option>
                          <option value="1">Left</option>
                          <option value="2">Right</option>
                        </select>
                        <br />
                        <div className="rerlbtn">
                          <button
                            className=" btn_Model"
                            onClick={() => {
                              registerUser();
                            }}
                          >
                            Register
                          </button>

                          <button
                            className="mx-2 btn_Model"
                            onClick={() => setregistor_Model(false)}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="remembertext2 text-center">
                        If you have already registered then{" "}
                        <Link className="text-theme1" to="/login">Login</Link>
                      </div>
                      <div className="remembertext2 text-center">
                        Copyright © 2024. All Rights Reserved.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
