import React from "react";

const Footer = () => {
  return (
    <>
      <div className="footers" id="contact">
        <footer className="container-fluid ">
          <div className="container-fluid">
            <div className="style-2">
              <div className="bottom-inner">
                <div className="tf-container">
                  <div className="row align-items-center bottom">
                    <div className="col-sm-6">
                      <div className="content-left">
                        <img
                          src="./assets/image/logo/logo.png"
                          alt="Image"
                          className="footerlogo"
                        />
                        <p className="copy-right">
                          Nexaglobal.live 2024 - ALL rights reserved
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <ul className="menu-bottom">
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <a href="#about">About</a>
                        </li>
                        <li>
                          <a href="#Benifits">Benifits</a>
                        </li>

                        <li>
                          <a href="#Faq">FAQs</a>
                        </li>
                        <li>
                          <a href="#contact">Contact</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
