import React from "react";
import "./DashFooter.css";

const DashFooter = () => {
  return (
    <div>
      <footer className="page-footer footer">
        <p className="mb-0">Copyright © nexaglobal.live 2024. All right reserved.</p>
      </footer>
    </div>
  );
};

export default DashFooter;